<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :collapseHeight="60" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="消息类型：" prop="type">
          <el-select size="medium" v-model="form.type" placeholder="请选择消息类型">
            <el-option v-for="(item,index) in typeList" :label="item.name" :value="item.type" :key="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发送状态：" prop="is_send">
          <el-select size="medium" v-model="form.is_send" placeholder="请选择发送状态">
            <el-option v-for="(item,index) in stateList" :label="item.name" :value="item.type" :key="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容：" prop="keyword">
          <el-input size="medium" v-model="form.keyword" placeholder="请输入内容"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      typeList: [
        { name: "全部", type: -1 },
        { name: "一次性消息", type: 0 },
        { name: "长期性消息", type: 1 },
      ],
      stateList: [
        { name: "全部", type: -1 },
        { name: "已撤回", type: 0 },
        { name: "已发送", type: 1 },
      ],
      rules: {},
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    pid: {
      get() {
        return this.form.pid == -2 ? "" : this.form.pid;
      },
      set(val) {
        this.form.pid = val;
      },
    },
  },
  components: {
    LayoutFilter,
  },
};
</script>