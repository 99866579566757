<template>
  <el-dialog title="发送消息" :visible="showSend" width="504px" @close="closeSend" class="dialog-vertical">
    <el-form ref="sendForm" :model="sendForm" label-width="80px" :rules="sendFormRules">
      <el-form-item label="接收对象：" prop="user_position_ids">
        <el-cascader id="filterCascader" style="width: 100%"
          v-model="sendForm.user_position_ids" :options="orgMemOptions" :props="prop" collapse-tags filterable
          placeholder="请选择架构（可搜索架构名称）" :show-all-levels="false">
        </el-cascader>
      </el-form-item>
      <el-form-item label="消息类型" prop="type">
        <el-select style="width: 100%" v-model="sendForm.type" placeholder="请选择消息类型">
          <el-option v-for="(item, index) in typeList" :label="item.name" :value="item.type" :key="index">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <el-input type="textarea" placeholder="请输入内容" v-model="sendForm.content" rows="5"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="closeSend">取消</el-button>
      <el-button size="small" type="primary" :loading="saveLoading" @click="handleSaveSend('sendForm')">确定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import {  sendMessage, orgMemberSelect } from "../../api/message_list";
export default {
  name: "SendMessage",
  props: ["showSend"],
  data() {
    return {
      sendForm: {
        content: "",
        type: "",
        // 用户id 数组
        user_position_ids: ""
      },
      // 接收对象选项
      orgMemOptions: {},
      typeList: [
        { name: "一次性消息", type: 0 },
        { name: "长期性消息", type: 1 },
      ],
      sendFormRules: {
        content: [
          { required: true, message: "请填写信息内容", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择消息类型", trigger: "blur" }],
        user_position_ids: [
          { required: true, message: "请选择接收对象", trigger: "blur" },
        ],
      },
      saveLoading: false,
      // 级联选择器配置
      prop: {
        value: "user_id",
        label: "name",
        expandTrigger: "hover",
        children: "_children",
        multiple: true,
        emitPath: false,
      },
    };
  },
  created() {
    this.getReceiveList();
  },
  methods: {
    handleSaveSend(formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          // 请求数据
          const PostData = {
            //接收对象 最新版固定为 -1
            receive: -1,
            // 内容
            content: this.sendForm.content,
            // 消息类型（0：一次性消息，1：长期性消息）
            type: this.sendForm.type,
            user_ids: this.sendForm.user_position_ids
          }
          console.log(PostData)
          sendMessage(PostData)
            .then((res) => {
              this.$emit("updateMsgList");
              this.closeSend();
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    closeSend() {
      this.$emit("handleCloseSend");
      this.$refs["sendForm"].resetFields();
    },
    getReceiveList() {
      orgMemberSelect({ include_first: 1 }).then(res => {
        this.orgMemOptions = res?.data || [];
      }).catch(() => {
        this.orgMemOptions = []
      })
    },
  },
};
</script>

<style scoped>
</style>
