import api from "@/base/utils/request";

//获取系统消息列表
export const messageList = data => {
  return api({
    url: "/admin/admin/message_system/index",
    method: "post",
    data
  });
};
//发送消息
export const sendMessage = data => {
  return api({
    url: "/admin/admin/message_system/saveSend",
    method: "post",
    data
  });
};
//接收对象
export const getReceive = data => {
  return api({
    url: "/admin/admin/message_system/getReceive",
    method: "post",
    data
  });
};

//删除消息
export const delMessage = data => {
  return api({
    url: "/admin/admin/message_system/deleteMessage",
    method: "post",
    data
  });
};
//撤回消息
export const recallMessage = data => {
  return api({
    url: "/admin/admin/message_system/recall",
    method: "post",
    data
  });
};

//重发消息
export const againSendMessage = data => {
  return api({
    url: "/admin/admin/message_system/againSend",
    method: "post",
    data
  });
};
// 组织/会员选择列表
export const orgMemberSelect = data => {
  return api({
    url: "/admin/cyc/organization/multiSelect",
    method: "post",
    data,
    notCancel: true
  });
};