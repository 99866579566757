<template>
  <div class="system-message" v-loading="loading">
    <el-button type="primary" size="small" @click="showSend = true"
      >新增消息</el-button
    >
    <div style="margin-top: 20px">
      <MessageScreen
        :filterForm="filterForm"
        :uploadFilter="updateList"
      ></MessageScreen>
    </div>
    <el-button
      style="margin-top: 20px"
      size="small"
      type="danger"
      @click="handleDel()"
      >删除
    </el-button>
    <el-table
      style="width: 100%; margin-bottom: 50px; margin-top: 20px"
      class="thead-light"
      stripe
      :data="message_list"
      @selection-change="handleSelection"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        v-for="(item, index) in message_table"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        :min-width="item.min_width ? item.min_width : ''"
        :width="item.width"
        :sortable="item.sortable"
      >
      </el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="scope">
          <el-button
            style="color: #3576ff"
            size="small"
            type="text"
            v-if="scope.row.is_send == 0"
            @click="handleResend(scope.row.id)"
            >重发</el-button
          >
          <el-button
            style="color: #3576ff"
            size="small"
            type="text"
            v-if="scope.row.is_send == 1"
            @click="handleRecall(scope.row.id)"
            >撤回</el-button
          >
          <el-button
            style="color: #3576ff"
            size="small"
            type="text"
            @click="deleteRow(scope.row)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    ></Pagination>
    <SendMessage
      :showSend="showSend"
      @handleCloseSend="showSend = false"
      @updateMsgList="RefreshList"
    >
    </SendMessage>
  </div>
</template>

<script>
import {
  messageList,
  delMessage,
  againSendMessage,
  recallMessage,
} from "../api/message_list";
import MessageScreen from "../components/SystemMessage/MessageScreen";
import Pagination from "@/base/components/Default/Pagination";
import SendMessage from "../components/SystemMessage/SendMessage";
export default {
  name: "message_system",
  data() {
    return {
      loading: false, //加载
      filterForm: {
        is_send: -1,
        type: -1,
        page_size: 15,
        keyword: "",
      },
      pageData: {},
      message_list: [],
      message_table: [
        { label: "ID", prop: "id", width: 60 },
        { label: "内容", prop: "content", min_width: 200 },
        { label: "消息类型", prop: "type_text", width: 180 },
        { label: "接收对象", prop: "receive_text", width: 110 },
        { label: "发送状态", prop: "is_send_text", width: 100 },
        { label: "发送时间", prop: "send_time", width: 230, sortable: true },
      ],
      showSend: false,
      selectId: [],
    };
  },
  components: {
    MessageScreen,
    Pagination,
    SendMessage,
  },
  created() {
    this.getMessageList(this.filterForm);
  },
  methods: {
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      this.getMessageList({ ...this.filterForm, ...e });
    },
    getMessageList(requestData) {
      this.loading = true;
      this.pageData = {};
      messageList(requestData)
        .then((res) => {
          this.message_list = res.data.data;
          this.pageData = res.data;
          this.loading = false;
        })
        .catch((err) => {});
    },
    // 刷新列表
    RefreshList() {
      const data = {
        page: this.pageData.current_page,
        ...this.filterForm,
      };
      this.getMessageList(data);
    },
    //撤回
    handleRecall(id) {
      this.$confirm("是否撤回该消息", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          this.loading = true;
          recallMessage({ id: id })
            .then((res) => {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.RefreshList();
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    //重发
    handleResend(id) {
      this.$confirm("是否重发该消息", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          this.loading = true;
          againSendMessage({ id: id })
            .then((res) => {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.RefreshList();
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    // 批量删除
    handleDel() {
      if (this.selectId.length) {
        this.$msgbox
          .confirm("确定要将选中数据永久删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ id: this.selectId });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(row) {
      this.$msgbox
        .confirm("确定要将此数据永久删除吗？", "提示", {
          type: "error",
        })
        .then((res) => {
          this.handleDelete({ id: row.id });
        })
        .catch((err) => {});
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      delMessage(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //选中项
    handleSelection(data) {
      let arr = [];
      data.forEach((e) => {
        arr.push(e.id);
      });
      this.selectId = arr;
    },
    updateList(e) {
      this.filterForm = e;
      this.getMessageList(this.filterForm);
    },
  },
};
</script>

<style scoped lang="scss">
.system-message {
}
</style>
